<template>
    <div class="flex flex-col justify-between my-5 h-full">
        <div>
            <ElementsGoBack @goBack="goBack" v-if="state.currentStep != 3" />
            <div v-if="state.currentStep == 1">
                <h1 class="text-2xl mt-1 mb-2">Konto erstellen</h1>
                <p>Sie sind ein Produzent und möchten ihre Produkte bei einer unserer Regionalmarken zertifizieren?</p>
                <ElementsForm path="register.step1" @submit="onFormNext" v-slot="{ validation }">
                    <ElementsInput :validation="validation" label="Firma" path="business.name" placeholder="Firmenname" v-model="form.tmpBusiness" required autofocus="autofocus" />
                    <ElementsButton type="submit" :loading="state.loading" showAs="full">Weiter</ElementsButton>
                </ElementsForm>
            </div>
            <div v-if="state.currentStep == 2">
                <h1 class="text-2xl mt-1 mb-2">{{ form.tmpBusiness }}</h1>
                <p>Super! Welche Anmeldedaten möchten Sie verwenden? </p>
                <ElementsForm path="register.step2" @submit="onSubmitRegister" v-slot="{ validation }">
                    <ElementsAlert :type="state.error.type" :active="state.error.visible">{{ state.error.message }}</ElementsAlert>
                    <div class="my-3">
                        <label class="">Name</label>
                        <div class="flex">
                            <ElementsInput :validation="validation" type="text" placeholder="Vorname" v-model="form.firstname" required noMargin first autofocus="autofocus" />
                            <ElementsInput :validation="validation" type="text" placeholder="Name" v-model="form.lastname" required noMargin second />
                        </div>
                    </div>
                    <ElementsInput :validation="validation" path="user.email" type="email" label="E-Mail" placeholder="E-Mail" v-model="form.email" required />
                    <ElementsInput :validation="validation" path="user.password" type="password" label="Passwort" placeholder="Passwort" v-model="form.password" required />
                    <ElementsInput :validation="validation" path="user.password" type="password" label="Passwort wiederholen" placeholder="Passwort" v-model="form.passwordRepeat" required />
                    <ElementsCheckbox class="my-2.5" :validation="validation" path="user.terms" required v-model="form.terms">
                        <template #label>
                            <label>
                                Ich akzeptiere die <NuxtLink to="/terms" target="_blank">Allgemeinen Geschätfbedingungen</NuxtLink> und die <NuxtLink to="/privacy" target="_blank">Datenschutzbestimmungen</NuxtLink>.
                                <span class="inline-block text-gray-400">*</span>
                            </label>
                        </template>
                    </ElementsCheckbox>
                    <ElementsButton type="submit" :loading="state.loading" showAs="full">Konto erstellen</ElementsButton>
                </ElementsForm>
            </div>
            <div class="flex flex-col items-center mt-24" v-if="state.currentStep == 3">
                    <h3 class="text-lg font-bold">{{ form.tmpBusiness }}</h3>
                    <p class="text-base">{{ form.email }}</p>
                    <div class="w-24 my-8 text-primary dark:drop-shadow-xl"><ElementsIcon icon="envelope" size="fullwidth" animate="bounce" /></div>
                    <h2 class="text-lg font-bold mb-4 text-center text-primary">Ihr Konto wurde erfolgreich erstellt.</h2>
                    <p class="text-center text-sm">Sie haben eine E-Mail von uns erhalten. Bitte klicken Sie auf den Bestätigungslink in der E-Mail, um das Konto zu aktivieren.</p>
            </div>
        </div>
        <ElementsSteps steps="3" :current="state.currentStep" />
    </div>
</template>
<script setup>
const emit = defineEmits(['goBack'])

const { register } = useStrapiAuth()

const form = reactive({
    email: null,
    password: null,
    passwordRepeat: null,
    firstname: null,
    lastname: null,
    terms: null,
    tmpBusiness: null,
})

const state = reactive({
    currentStep: 1,
    loading: false,
    error: {
        visible: false,
        message: null,
        type: 'warning',
    },
})

const goBack = () => {
    state.error.visible = false
    if(state.currentStep == 1) return emit('goBack')
    state.currentStep--
}
const onFormNext = async () => {
    state.currentStep++
}
const onSubmitRegister = async () => {
    state.loading = true
    try {
        if(form.password != form.passwordRepeat){
            state.error.message = 'Die beiden Passwörter stimmen nicht überein.'
            state.error.type = 'warning'
            state.error.visible = true
            state.loading = false
            return
        } 
        if(!form.terms){
            state.error.message = 'Bitte akzeptieren Sie die AGBs und Datenschutzbestimmungen.'
            state.error.type = 'warning'
            state.error.visible = true
            state.loading = false
            return
        } 
        await register(form)
        state.currentStep++    
    } catch (e) {
        state.loading = false
        state.error.message = 'Die Registrierung war nicht erfolgreich. Ist die E-Mail korrekt oder haben Sie bereits ein Konto mit dieser E-Mail?'
        state.error.type = 'error'
        state.error.visible = true
    }
}
</script>